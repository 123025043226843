<template>
    <div class="container">
        <h3 class="text-center"> Add Configuration for DC </h3>
        <div class="row justify-content-center">
            <div class="col-md-12 d-flex text-center">
                <div class="col-md-4">
                    <Multiselect v-model="serviceToSave" tag-placeholder="Add this as new service" placeholder="Search or add a service" :options="serviceList" mode="tags" max="1" :searchable="true" :createTag="true" :multiple="false" @tag="addService" tag-position="bottom" @select="changeMultiselect"></Multiselect>
                </div>
                <div class="col-md-4">
                    <Multiselect v-model="dcToSave" tag-placeholder="Add this as new dc" placeholder="Search or add a dc" :options="dcList" mode="tags" max="1" :searchable="true" :createTag="true" :multiple="false" @tag="addDc" tag-position="bottom" @select="changeMultiselect"></Multiselect>
                </div>
                <div class="col-md-4">
                    <button class="btn btn-primary btn-block waves-effect" v-on:click="saveConfiguration()"> Save </button>
                    <div class="text-success text-right"> {{ responseSave }} </div>
                </div>
            </div>
            <div class="col-md-12 mt-4">
                <v-ace-editor v-model:value="contentConfiguration" @init="editorInit" lang="json" theme="chrome" style="margin: 0 15px 0 15px; height: 70vh;" />
            </div>
        </div>
    </div>

</template>
<script>

    import Multiselect from '@vueform/multiselect';
    import { VAceEditor } from 'vue3-ace-editor';

    import 'ace-builds/src-noconflict/mode-json';
    import 'ace-builds/src-noconflict/theme-chrome';

    export default {
        props: [
            'selectedServiceFromView',
            'selectedDcFromView'
        ],
        data() {
            return {
                loading: true,
                errored: false,
                serviceList: [],
                dcList: [],
                dcServiceList: [],
                serviceToSave: false,
                dcToSave: false,
                contentConfiguration: "",
                responseSave: ""
            }
        },
        components: { 
            Multiselect,
            VAceEditor
        },
        mounted() {
            console.log('Component mounted.');
            this.getDashboard( this.selectedServiceFromView, this.selectedDcFromView );
        },
        methods: {
            getDashboard: function( service = "", dc = "") {
                axios
                    .get(location.protocol + '//' + document.domain + '/get-dashboard/' + service + '/' + dc)
                    // .get(location.protocol + '//' + document.domain + '/get-dashboard')
                    .then(response => {
                        console.log(response);
                        this.serviceList = response.data.serviceList;
                        this.dcList = response.data.dcList;
                        this.dcServiceList = response.data.dcServiceList;
                        this.serviceToSave = [ response.data.selectedService ];
                        this.dcToSave = [ response.data.selectedDc ];
                        this.changeMultiselect();
                    })
                    .catch(error => {
                        console.log(error)
                        this.errored = true
                    })
            },
            addService: function (newService) {
              this.serviceList.push(newService);
              this.serviceToSave = newService;
            },
            addDc: function (newDc) {
              this.dcList.push(newDc);
              this.dcToSave = newDc;
            },
            editorInit: function (editor) {
                console.log('editor');
                // console.log(editor);
            },
            saveConfiguration: function() {
                let self = this;
                this.responseSave = '';
                console.log('saveConfiguration');
                if ( !this.serviceToSave ) {
                    Swal.fire({
                      title: 'Attention!',
                      text: 'Service must be filled',
                      icon: 'error',
                      showConfirmButton: false,
                      showCancelButton: true,
                      cancelButtonText: 'Close'
                    });
                    return false;
                }
                if ( !this.dcToSave ) {
                    Swal.fire({
                      title: 'Attention!',
                      text: 'DC must be filled',
                      icon: 'error',
                      showConfirmButton: false,
                      showCancelButton: true,
                      cancelButtonText: 'Close'
                    });
                    return false;
                }
                let service = this.serviceToSave[0];
                let datacenter = this.dcToSave[0];
                let configuration = this.contentConfiguration;

                Swal.fire({
                  title: 'Add new DC Service configuration',
                  text: 'An existing configuration will be overwritten',
                  icon: 'warning',
                  confirmButtonText: 'Continue',
                  showCancelButton: true,
                  cancelButtonText: 'Return'
                }).then(function(result) {
                    if (result.value) {
                        axios.post(location.protocol + '//' + document.domain + '/configuration/save', {
                          service, datacenter, configuration
                        }).then(response => {
                            if (response.data.result) {
                                console.log('ok saved');
                                self.getDashboard( service, datacenter );
                                self.responseSave = response.data.isNew ? 'New record inserted' : 'Updated record';
                            } else {
                                let error = response.data.error_specs ? response.data.error_specs : response.data.error.desc;
                                Swal.fire({
                                  title: 'Attention!',
                                  text: error,
                                  icon: 'error',
                                  showConfirmButton: false,
                                  showCancelButton: true,
                                  cancelButtonText: 'Return'
                                });
                            }
                        })
                        .catch(error => {
                            alert('axios error');
                            console.log(error);
                        });
                    }
                });
            },
            changeMultiselect: function() {
                console.log('Change multiselect');
                var self = this;
                this.contentConfiguration = "";
                this.dcServiceList.forEach( function(dcServiceElem, dcServiceIndex) {
                    console.log(dcServiceElem.service + ' against ' + self.serviceToSave[0]);
                    console.log(dcServiceElem.dc + ' against ' + self.dcToSave[0]);
                    console.log(dcServiceElem.configuration);
                    if ( dcServiceElem.service == self.serviceToSave[0] && dcServiceElem.dc == self.dcToSave[0] ) {
                        self.contentConfiguration = JSON.stringify(dcServiceElem.configuration, null, 2);
                        return;
                    }
                });
            }
        }
    }

</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
</style>