<template>
    <div class="container">
        <h3 class="text-center"> Configuration list </h3>
        <div class="row justify-content-center">
            <div class="col-md-12 d-flex">
                <div class="project-dcservice-list-table">
                    <div class="mb-2">
                        Show
                        <select class="mb-2 btn btn-sm btn-outline-secondary select-number-entries" v-model="rowForPage" v-on:change="addFilter('elements', $event.target.value, $event, 'showentries')">
                            <option v-for="entry in entries" :value="entry" :selected="rowForPage === entry">{{entry}}</option>
                        </select>
                        entries
                    </div>
                    <div class="element-wrapper">
                        <div class="element-box-tp">
                            <div class="element-box-tp">
                                <div class="table-responsive" style="overflow-x: scroll;">
                                    <table class="table table-striped">
                                        <thead>
                                            <tr>
                                                <th class="orderable-column" v-on:click="addFilter('ordering', ['service', alterOrderingType() ], $event, 'ordering')">
                                                    <span v-if="orderingColumn == 'service'">
                                                        <i v-if="orderingType == 'asc'" class="icon-ordering os-icon os-icon-arrow-up2"></i>
                                                        <i v-else class="icon-ordering os-icon os-icon-arrow-down"></i>
                                                    </span>
                                                    <span v-else>
                                                        <i class="icon-ordering os-icon os-icon-arrow-down" style="color: #ddd"></i>
                                                    </span>
                                                    Service
                                                </th>
                                                <th class="orderable-column" v-on:click="addFilter('ordering', ['dc', alterOrderingType() ], $event, 'ordering')">
                                                    <span v-if="orderingColumn == 'dc'">
                                                        <i v-if="orderingType == 'asc'" class="icon-ordering os-icon os-icon-arrow-up2"></i>
                                                        <i v-else class="icon-ordering os-icon os-icon-arrow-down"></i>
                                                    </span>
                                                    <span v-else>
                                                        <i class="icon-ordering os-icon os-icon-arrow-down" style="color: #ddd"></i>
                                                    </span>
                                                    Datacenter
                                                </th>
                                                <th>Configuration</th>
                                                <th>Updated</th>
                                                <th>Created</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="dcService in configurationList">
                                                <td> {{dcService.service}} </td>
                                                <td> {{dcService.dc}} </td>
                                                <td> 
                                                    <button class="btn btn-sm btn-block btn-outline-secondary" data-toggle="modal" data-target="#configuration-modal" v-on:click="openConfiguration(dcService)"> View </button> 
                                                </td>
                                                <td> {{ formatDate(dcService.updated_at) }} </td>
                                                <td> {{ formatDate(dcService.created_at) }} </td>
                                                <td class="text-center d-flex">
                                                    <button class="btn btn-sm btn-outline-info mr-2" v-on:click="editDcServiceConfiguration(dcService.service, dcService.dc)"> Edit </button>
                                                    <button class="btn btn-sm btn-outline-danger" v-on:click="deleteDcServiceConfiguration(dcService.id)"> Delete </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="controls-below-table mt-3">
                                    <div class="table-records-info">
                                        Showing records
                                        <span v-if="countFilteredPages">
                                            {{displayResultsFrom}}
                                        </span>
                                        <span v-else>
                                            0
                                        </span>
                                        -
                                        <span v-if="displayResultsTo <= countFilteredPages">
                                            {{displayResultsTo}}
                                        </span>
                                        <span v-else>
                                            {{countFilteredPages}}
                                        </span>
                                        of {{countFilteredPages}}
                                    </div>
                                    <div class="table-records-pages" v-if="Math.ceil(countFilteredPages/rowForPage) <= 5">
                                        <ul v-if="Math.ceil((countFilteredPages/rowForPage)) <= 1">
                                            <li>
                                                <a class="current text-primary pagination-dcservice-list"> 1 </a>
                                            </li>
                                        </ul>
                                        <ul v-else>
                                            <li v-for="n in Math.ceil(countFilteredPages/rowForPage)">
                                                <a :class="{'current text-primary pagination-dcservice-list' : n == currentPage, 'pagination-dcservice-list' : n != currentPage}" v-on:click="changePage(n)"> {{n}}</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="table-records-pages" v-else>
                                        <ul>
                                            <li v-if="(parseInt(currentPage)-1) < 1">
                                                <a class="">-</a>
                                            </li>
                                            <li v-else>
                                                <a class="pagination-dcservice-list" v-on:click="changePage(parseInt(currentPage)-1)">Previous</a>
                                            </li>
                                            <li>
                                                <a class="current text-primary pagination-dcservice-list">Current</a>
                                            </li>
                                            <li v-if="(parseInt(currentPage)+1) > Math.ceil(countFilteredPages/rowForPage)">
                                                <a class="">-</a>
                                            </li>
                                            <li v-else>
                                                <a class="pagination-dcservice-list" v-on:click="changePage(parseInt(currentPage)+1)">Next</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal View configuration -->
    <div class="onboarding-modal modal fade animated show" id="configuration-modal" role="dialog" aria-modal="true" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-centered modal-lg" role="document">
            <div class="modal-content text-center">
                <button aria-label="Close" class="close" data-dismiss="modal" type="button"><span class="os-icon os-icon-close"></span></button>
                <div class="onboarding-content with-gradient">
                    <h4 class="onboarding-title mt-2">Service: {{ thisConfiguration.service }} - Datacenter: {{ thisConfiguration.dc }}</h4>
                    <div class="modal-body">
                        <div class="row mb-2">
                            <div class="col-lg-12 col-md-12 col-xs-12">
                                <pre style="overflow: scroll; max-height: 400px; text-align: left;"><code>{{ thisConfiguration.configuration }}</code></pre>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary waves-effect" data-dismiss="modal">Close</button>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div>
</template>
<script>
    import moment from 'moment';

    export default {
        props: [
        ],
        data() {
            return {
                configurationList: [],
                countTotalPages: 0,
                countFilteredPages: 0,
                currentPage: 1,
                rowForPage: 100,
                entries: [10, 20, 50, 100],
                displayResultsFrom: 1,
                displayResultsTo: 20,
                orderingColumn: 'order',
                orderingType: 'asc',
                loading: true,
                errored: false,
                filters: [],
                qsFilters: '',
                searchstring: '',
                thisConfiguration: false
            }
        },
        mounted() {
            console.log('Component mounted.');
            axios
                .get(location.protocol + '//' + document.domain + '/get-configuration-list')
                .then(response => {
                    // console.log(response);
                    this.configurationList = response.data.resourceQueryList;
                    this.countTotalPages = response.data.totalPages;
                    this.countFilteredPages = response.data.filteredPages;
                    this.currentPage = response.data.currentPage;
                    this.displayResultsFrom = (this.rowForPage * response.data.currentPage) - (this.rowForPage - 1);
                    this.displayResultsTo = this.rowForPage * response.data.currentPage;
                    this.orderingColumn = response.data.columnNameOrdering;
                    this.orderingType = response.data.columnTypeOrdering;
                })
                .catch(error => {
                    console.log(error)
                    this.errored = true
                })
                .finally(() => this.loading = false)
        },
        methods: {
            formatDate: function( date ) {
                if (date) {
                    return moment(String(date)).format('DD/MM/YYYY HH:mm');
                }
            },
            openConfiguration: function (dcService = false) {
                // console.log(dcService);
                let self = this;
                if ( !dcService ) {
                    this.thisConfiguration.service = '';
                    this.thisConfiguration.dc = '';
                    this.thisConfiguration.configuration = '';
                }
                this.thisConfiguration = Object.assign(dcService, {})
            },
            fromObjectToQS: function(params) {
                console.log('called fromObjToQs');
                // console.log(params);
                const qs = Object.keys(params)
                    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
                    .join('&');
                // console.log(qs);                         
                this.qsFilters = qs;
            },
            getConfigurationList: function() {
                this.loading = true;
                this.errored = false;
                // console.log(this.filters);
                // console.log(this.filters.page);
                this.fromObjectToQS(this.filters);
                axios
                    .get(location.protocol + '//' + document.domain + '/get-configuration-list?' + this.qsFilters)
                    .then(response => {
                        // console.log(response);
                        this.configurationList = response.data.resourceQueryList;
                        this.countTotalPages = response.data.totalPages;
                        this.countFilteredPages = response.data.filteredPages;
                        this.currentPage = response.data.currentPage;
                        this.displayResultsFrom = (this.rowForPage * response.data.currentPage) - (this.rowForPage - 1);
                        this.displayResultsTo = this.rowForPage * response.data.currentPage;
                        this.orderingColumn = response.data.columnNameOrdering;
                        this.orderingType = response.data.columnTypeOrdering;
                    })
                    .catch(error => {
                        console.log(error)
                        this.errored = true
                    })
                    .finally(() => this.loading = false)
            },
            changePage: function(page) {
                // console.log(page);
                if (page < 1) page = 1;
                if (page > (Math.ceil(this.countFilteredPages / this.rowForPage))) page = Math.ceil(this.countFilteredPages / this.rowForPage);
                // console.log(page);
                this.filters['page'] = page;
                this.filters['elements'] = this.rowForPage;
                // console.log('call get structure');
                console.log('clean selected');
                this.getConfigurationList();
            },
            addFilter: function(type, value, event = false, elementType = 'checkbox') {
                console.log('call add filter');
                // console.log(event);
                let tempFilters = Object.assign({}, this.filters);
                // console.log(tempFilters);
                if (elementType == 'searchstring') {
                    if (value) {
                        if (tempFilters[type] == undefined) {
                            tempFilters[type] = [];
                        }
                        tempFilters[type] = value;
                    } else {
                        delete tempFilters[type];
                    }
                }
                if (elementType == 'ordering') {
                    if (value) {
                        if (tempFilters[type] == undefined) {
                            tempFilters[type] = [];
                        }
                        tempFilters[type] = JSON.stringify({ 'column': value[0], 'type': value[1] });
                    } else {
                        delete tempFilters[type];
                    }
                }
                if (elementType == 'showentries') {
                    if (value) {
                        if (tempFilters[type] == undefined) {
                            tempFilters[type] = [];
                        }
                        tempFilters[type] = value;
                        this.rowForPage = value;
                    } else {
                        delete tempFilters[type];
                    }
                }
                this.filters = tempFilters;
                //reset page
                this.filters['page'] = 1;
                this.getConfigurationList();
            },
            editDcServiceConfiguration: function( service, datacenter ) {
                console.log('called editDcServiceConfiguration');
                window.location.href = location.protocol + '//' + document.domain + '/dashboard/' + service +  '/' + datacenter;
            },
            deleteDcServiceConfiguration: function( dcServiceID ) {
                console.log('called deleteDcServiceConfiguration');
                let self = this;
                Swal.fire({
                  title: 'Delete DC Service configuration?',
                  text: 'This action is irreversible',
                  icon: 'warning',
                  confirmButtonText: 'Continue',
                  showCancelButton: true,
                  cancelButtonText: 'Return'
                }).then(function(result) {
                    if (result.value) {
                        axios.post(location.protocol + '//' + document.domain + '/configuration/delete', {
                          dcServiceID
                        }).then(response => {
                            if (response.data.result) {
                                console.log('ok saved');
                                self.getConfigurationList();
                            } else {
                                let error = response.data.error_specs ? response.data.error_specs : response.data.error.desc;
                                Swal.fire({
                                  title: 'Attention!',
                                  text: error,
                                  icon: 'error',
                                  showConfirmButton: false,
                                  showCancelButton: true,
                                  cancelButtonText: 'Return'
                                });
                            }
                        })
                        .catch(error => {
                            alert('axios error');
                            console.log(error);
                        });
                    }
                });
            }
        }
    }
</script>
<style scoped>
    .orderable-column {
        cursor: pointer;
    }

    .select-number-entries {
        vertical-align: initial;
    }

    .icon-ordering {
        font-size: 20px;
        vertical-align: bottom;
        line-height: 0.8;
    }

    .table {
        width: 100%;
        table-layout: fixed;
    }

    .table th, .table td {
        padding: .5rem 1rem;
        white-space: initial;
        word-break: break-all;
        font-size: 12px;
    }

    .table th {
        font-size: 10px !important;
    }

    .pagination-dcservice-list {
        cursor: pointer;
    }

    .table td, .table th {
        white-space: nowrap;
    }

    .content-box-instance-dcservice-list {
        max-width: calc(100vw - 200px);
    }

    .top-bar .top-menu-controls {
        margin: 0;
    }

    .top-bar .top-menu-controls .element-search input,
    .element-search.search-dcservice-list-element,
    .updated-date,
    .discover-date {
        width: 100%;
    }

    .updated-date,
    .discover-date {
        border: 1px solid #ddd;
        border-radius: 10px;
    }

    button.btn.btn-primary.show-filters-dcservice-list {
        width: 50%;
    }

    /* .equal>.col-3>.element-box {
        min-height: 100px;
    } */

    .element-box-dcservice-list {
        padding: .2rem .5rem;
    }

    label.form-check-label {
        margin-right: .5rem;
    }

    .text-bold {
        font-weight: bold;
    }

    a[class^='pagination-'], a[class*=' pagination-'] {
        border: 2px solid rgba(218, 226, 243, 0.4) !important;
        padding: 3px 7px;
        border-radius: 3px;
    }

    .controls-below-table {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        font-size: 0.81rem;
    }

    .controls-below-table .table-records-pages ul {
        list-style: none;
    }
</style>
