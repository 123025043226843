import 'bootstrap';

// require('alpinejs');

import { createApp } from 'vue';

window.Swal = require('sweetalert2');
window.axios = require('axios');
window._ = require('lodash');

let app = createApp({});

//Components
app.component('main-dashboard', require('./components/MainDashboard.vue').default);
app.mount("#main-dashboard");
app.component('configuration-list', require('./components/ConfigurationList.vue').default);
app.mount("#configuration-list");
app.component('configuration-preview', require('./components/ConfigurationPreview.vue').default);
app.mount("#configuration-preview");