<template>
    <div class="container">
        <h3 class="text-center"> Configuration preview </h3>
        <div class="row justify-content-center">
            <div class="col-md-12 d-flex text-center">
                <div class="col-md-6">
                    <input v-model="serviceToSave" class="form-control" type="text" placeholder="Type a name of a new service to view the configuration">
                </div>
                <div class="col-md-6">
                    <Multiselect v-model="dcToSave" tag-placeholder="Add this as new dc" placeholder="Search or add a dc" :options="newDcList" mode="tags" max="1" :searchable="true" tag-position="bottom" @select="changeMultiselect"></Multiselect>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div v-if="dcServiceListPreview.length" class="mt-2 col-md-12 list-container text-center plr-30">
                <div class="table-responsive" style="overflow-x: scroll;">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Service</th>
                                <th>Datacenter </th>
                                <th>Configuration</th>
                                <th>Updated</th>
                                <th>Created</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="dcService in dcServiceListPreview">
                                <td> {{dcService.service}} </td>
                                <td> {{dcService.dc}} </td>
                                <td> 
                                    <button class="btn btn-sm btn-block btn-outline-secondary" data-toggle="modal" data-target="#configuration-modal" v-on:click="openConfiguration(dcService)"> View </button> 
                                </td>
                                <td> {{ formatDate(dcService.updated_at) }} </td>
                                <td> {{ formatDate(dcService.created_at) }} </td>
                                <td class="text-center">
                                    <button class="btn btn-sm btn-outline-info mr-2" v-on:click="editDcServiceConfiguration(dcService.service, dcService.dc)"> Edit </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-12 mt-2">
                <v-ace-editor v-model:value="contentConfiguration" @init="editorInit" lang="json" theme="chrome" style="margin: 0 15px 0 15px; height: 70vh;" />
            </div>
        </div>
    </div>
    <!-- Modal View configuration -->
    <div class="onboarding-modal modal fade animated show" id="configuration-modal" role="dialog" aria-modal="true" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-centered modal-lg" role="document">
            <div class="modal-content text-center">
                <button aria-label="Close" class="close" data-dismiss="modal" type="button"><span class="os-icon os-icon-close"></span></button>
                <div class="onboarding-content with-gradient">
                    <h4 class="onboarding-title mt-2">Service: {{ thisConfiguration.service }} - Datacenter: {{ thisConfiguration.dc }}</h4>
                    <div class="modal-body">
                        <div class="row mb-2">
                            <div class="col-lg-12 col-md-12 col-xs-12">
                                <pre style="overflow: scroll; max-height: 400px; text-align: left;"><code>{{ thisConfiguration.configuration }}</code></pre>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary waves-effect" data-dismiss="modal">Close</button>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div>
</template>
<script>

    import Multiselect from '@vueform/multiselect';
    import { VAceEditor } from 'vue3-ace-editor';
    import moment from 'moment';

    import 'ace-builds/src-noconflict/mode-json';
    import 'ace-builds/src-noconflict/theme-chrome';

    export default {
        props: [
            'dcList',
            'dcServiceList'
        ],
        data() {
            return {
                loading: true,
                errored: false,
                newDcList: [],
                serviceToSave: '',
                dcToSave: false,
                contentConfiguration: "",
                dcServiceListPreview: [],
                thisConfiguration: false
            }
        },
        components: { 
            Multiselect,
            VAceEditor
        },
        watch: {
            serviceToSave: function () {
                this.debouncedLoadConfiguration();
            }
        },
        mounted() {
            console.log('Component mounted.');
            this.newDcList = this.dcList;
        },
        methods: {
            formatDate: function( date ) {
                if (date) {
                    return moment(String(date)).format('DD/MM/YYYY HH:mm');
                }
            },
            openConfiguration: function (dcService = false) {
                // console.log(dcService);
                let self = this;
                if ( !dcService ) {
                    this.thisConfiguration.service = '';
                    this.thisConfiguration.dc = '';
                    this.thisConfiguration.configuration = '';
                }
                this.thisConfiguration = Object.assign(dcService, {})
            },
            editorInit: function (editor) {
                console.log('editor');
                // console.log(editor);
            },
            debouncedLoadConfiguration: _.debounce(function() {
                this.changeMultiselect();
            }, 1000),
            changeMultiselect: function() {
                console.log('Change multiselect, load configuration');
                var self = this;
                this.contentConfiguration = "";
                if ( !self.serviceToSave ) {
                    Swal.fire({
                      title: 'Attention!',
                      text: 'Name of service must be filled!',
                      icon: 'error',
                      showConfirmButton: false,
                      showCancelButton: true,
                      cancelButtonText: 'Return'
                    });
                    return;
                }
                if ( !self.dcToSave ) {
                    return;
                }
                axios
                    .get(location.protocol + '//' + document.domain + '/api/preview-configuration/' + self.serviceToSave + '/' + self.dcToSave)
                    .then(response => {
                        console.log(response);
                        self.contentConfiguration = JSON.stringify(response.data.finalJson, null, 2);
                        self.dcServiceListPreview = response.data.dcServiceList;
                    })
                    .catch(error => {
                        console.log(error)
                        alert('Axios error');
                    })
                    .finally(() => this.loading = false)
            },
            editDcServiceConfiguration: function( service, datacenter ) {
                console.log('called editDcServiceConfiguration');
                window.location.href = location.protocol + '//' + document.domain + '/dashboard/' + service +  '/' + datacenter;
            }
        }
    }

</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
    .plr-30 {
        padding: 0 30px 0 30px;
    }
</style>